<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>后台管理</el-breadcrumb-item>
      <el-breadcrumb-item>创作中心</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 10px 0px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input size="small" placeholder="请输入标题" v-model="queryInfo.title" clearable></el-input>
        </el-col>
        <el-col v-permission="['ROLE_ADMIN']" :span="4">
          <el-input size="small" placeholder="请输入作者" v-model="queryInfo.author" clearable></el-input>
        </el-col>
        <el-col :span="9">
          <el-date-picker
            v-model="queryInfo.createTime"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            prefix-icon="el-input__icon el-icon-date"
            :default-time="['00:00:00', '00:00:00']"
          >></el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-select size="mini" v-model="queryInfo.status" clearable placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getManageArticleInfo()"
          >搜索</el-button>
        </el-col>
      </el-row>
      <el-table size="mini" :data="artilceList" border style="margin-top: 10px">
        <!-- 添加展开列 -->
        <!-- <el-table-column type="expand"></el-table-column> -->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status==1">草稿</el-tag>
            <el-tag v-if="scope.row.status==2" type="warning">审核中</el-tag>
            <el-tag v-if="scope.row.status==3" type="danger">审核未通过</el-tag>
            <el-tag v-if="scope.row.status==4" type="success">审核通过</el-tag>
            <el-tag v-if="scope.row.status==5" type="info">已经删除</el-tag>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="摘要" prop="digest"></el-table-column>
        <el-table-column label="封面" prop="cover"></el-table-column>
        <el-table-column label="作者" prop="author"></el-table-column>
        <el-table-column label="教程" prop="cpath"></el-table-column>
        <el-table-column label="发布时间" width="150px" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="400px">
          <template slot-scope="scope">
            <router-link
              target="_blank"
              :to="{name:'articleInfo',params:{articleId: scope.row.id}}"
            >
              <el-button size="mini" type="primary" icon="el-icon-view">浏览</el-button>
            </router-link>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              style="margin-left:5px;"
              @click="editArticle(scope.row.id)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-warning"
              @click="changeStatus(scope.row.id,'4')"
            >置为通过</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="removeArticle(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "articleManage",
  data() {
    return {
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      artilceList: [],
      tagList: [],
      statusList: [
        { label: "草稿", value: "1" },
        { label: "审核中", value: "2" },
        { label: "审核未通过", value: "3" },
        { label: "审核通过", value: "4" },
        { label: "已经删除", value: "5" }
      ]
    };
  },
  computed: {
    username() {
      return this.$store.state.permission.user.username;
    }
  },
  mounted() {
    this.getManageArticleInfo();
    // this.getArticleTag();
  },
  methods: {
    // 文章列表
    async getManageArticleInfo() {
      let query = Object.assign({}, this.queryInfo);
      if (this.queryInfo.createTime) {
        query.beginCreateTime = this.queryInfo.createTime[0];
        query.endCreateTime = this.queryInfo.createTime[1];
        query.createTime = [];
      }
      const { data: res } = await this.$api.getManageArticleInfo(query);
      this.artilceList = res.data.list;
      this.total = res.data.total;
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getManageArticleInfo();
    },
    handleCurrentChange(current) {
      this.queryInfo.pageNum = current;
      this.getManageArticleInfo();
    },
    // 修改文章
    editArticle(id) {
      this.$router.push({ name: "articleEdit", params: { articleId: id } });
    },
    async changeStatus(id,status){
      const { data: res } = await this.$api.changeStatus(id,status)
      await this.getManageArticleInfo()      
    },
    // 删除文章
    async removeArticle(id) {
      const result = await this.$confirm("是否删除该文章?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$api.deleteArticleInfo(id);
      this.getManageArticleInfo();
    },
    // 文章标签
    async getArticleTag() {
      const { data: res } = await this.$api.getArticleTag({
        username: this.username
      });
      this.tagList = res.data;
    }
  }
};
</script>

<style lang="less" scoped>
</style>